.body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--color-bg-system);
    overflow: hidden;
}

.headerModule {
    width: calc(2* var(--graphics-size-4xl));
    padding: var(--space-xs);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

:global {
    .Header-LeftSide {
        height: 100%;
        overflow: hidden;
        flex: 1 1 fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: var(--space-2xs)
    }

    .Header-RightSide {
        width: fit-content;
        height: 100%;
        overflow: hidden;
        flex: 1 1 fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: var(--space-2xs)
    }
}

.feedbackModal {
    width: calc(5* var(--graphics-size-4xl));
    height: calc(5* var(--graphics-size-4xl));
    overflow: hidden;
}

.signoutModal {
    width: calc(3*var(--graphics-size-4xl));
    height: var(--graphics-size-3xl);
    overflow: hidden;

}

.signoutLoadingModal {
    width: var(--graphics-size-4xl);
    height: var(--graphics-size-4xl);

}

.plug {
    margin: auto;
}

.userInfo {
    max-width: calc(2* var(--space-6xl));
    overflow: hidden;
    p{
        text-overflow: ellipsis;
        white-space: nowrap; 
        margin: 0;
        overflow: hidden;
    }
}