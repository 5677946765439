@import "./styles/variables.scss";

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.body,
.error {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-bg-secondary);
  color: var(--color-typo-primary);
}

.error {
  display: flex;
  justify-content: center;
}

#root {
  width: 100%;
  height: 100vh;
}

/* Global styles */
.Table-ContentCell {
  padding: var(--cell-padding-vertical) !important;

}

.TableCell-Wrapper_isHeader {
  padding: var(--space-s) var(--space-xl) !important;
  background: var(--color-bg-secondary);
  justify-content: space-around !important;

  .TableHeader-Buttons {
    right: var(--space-2xs) !important;
  }
}