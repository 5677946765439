.body{
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	.header {
		width: 100%;
		flex: 0 0 var(--space-3xl);
		background: var(--color-bg-system);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: var(--space-xs);
		background: var(--color-bg-system);
		overflow: hidden;
	}
	.content{
		margin-top: auto;
		width: 100%;
		display: flex;
		padding: var(--space-xs);
		flex: 0 0 var(--space-4xl);
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
	}
}

/* old */
.signout {
	width: 100%;
	height: 100%;

	&-main {
		width: 100%;
		height: 100%;
		justify-content: flex-end;
		align-items: center;
		padding-right: 15px;
		gap: 15px;
	}
	&-header {
		width: 100%;
		height: 100%;
		align-items: center;
		border-bottom: 1px solid var(--color-bg-system);
		justify-content: space-between;
		&-text {
			margin-left: 15px;
		}
	}
}
