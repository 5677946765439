.Theme_color_gpnDark {
  --space-4xs: 1px;

  --lists-stats-width: 330px;

  --header-height: 100px;
  --comment-height: 600px;

  --select-item-hover: rgba(0, 175, 250, 0.21);

  --header-bg: var(--color-control-bg-default);
  --content-bg: var(--color-bg-secondary);

  --base-color: var(--color-bg-default);

  --card-false-note-reason: #3366cc;
  --card-new-bug-reason: #996600;
  --card-new-form-state-reason: #cc3366;
  --card-change-tech-proc-reason: #003300;
  --card-unknown-reason: #666600;
  --card-unknown-bug-reason: #6633ff;

  --card-trigger-reason-bg: #b4c3b4;
  --card-trigger-reason-text: #030;

  --card-opened-status: var(--color-bg-alert);
  --card-in-progress-status: var(--color-bg-normal);
  --card-confirmed-status: var(--color-bg-warning);
  --card-monitoring-status: var(--color-bg-caution);
  --card-closed-status: var(--color-typo-system);

  --model-normal-state: var(--color-bg-success);
  --model-offline-state: var(--color-typo-system);
  --model-anomaly-state: var(--color-bg-alert);

  --model-type-bg: #383055;
  --model-type-text: #9966ff;
}

// TODO: переопределить
.Theme_color_gpnDefault {
  --space-4xs: 1px;

  --lists-stats-width: 330px;

  --header-height: 100px;
  --comment-height: 600px;

  --select-item-hover: rgba(0, 175, 250, 0.21);

  --header-bg: var(--color-control-bg-default);
  --content-bg: var(--color-bg-secondary);

  --base-color: var(--color-bg-default);

  --card-false-note-reason: #3366cc;
  --card-new-bug-reason: #996600;
  --card-new-form-state-reason: #cc3366;
  --card-change-tech-proc-reason: #003300;
  --card-unknown-reason: #666600;
  --card-unknown-bug-reason: #6633ff;

  --card-trigger-reason-bg: #b4c3b4;
  --card-trigger-reason-text: #030;

  --card-opened-status: var(--color-bg-alert);
  --card-in-progress-status: var(--color-bg-normal);
  --card-confirmed-status: var(--color-bg-warning);
  --card-monitoring-status: var(--color-bg-caution);
  --card-closed-status: var(--color-typo-system);

  --model-normal-state: var(--color-bg-success);
  --model-offline-state: var(--color-typo-system);
  --model-anomaly-state: var(--color-bg-alert);

  --model-type-bg: #8c79d4;
  --model-type-text: #221836;
}