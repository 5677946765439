.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: var(--color-bg-secondary);
}

.header-container {
  width: 100%;
  flex: 0 0 var(--space-4xl);
  overflow: hidden;
}

.router-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
}

.status {
  --base-status-bg-color: var(--base-color);
  background-color: var(--base-status-bg-color);
}

.Opened {
  --base-status-bg-color: var(--card-opened-status);
}

.InProgress {
  --base-status-bg-color: var(--card-in-progress-status);
}

.Approved {
  --base-status-bg-color: var(--card-confirmed-status);
}

.Monitoring {
  --base-status-bg-color: var(--card-monitoring-status);
}

.Closed {
  --base-status-bg-color: var(--card-closed-status);
}

.Normal {
  --base-status-bg-color: var(--model-normal-state);
}

.Offline {
  --base-status-bg-color: var(--model-offline-state);
}

.Anomaly {
  --base-status-bg-color: var(--model-anomaly-state);
}

.triggerReason {
  --base-reason-bg-color: var(--base-color);
  background-color: var(--base-reason-bg-color);
}

.falseNote {
  --base-reason-bg-color: var(--card-false-note-reason);
}

.newBug {
  --base-reason-bg-color: var(--card-new-bug-reason);
}

.newNormState {
  --base-reason-bg-color: var(--card-new-form-state-reason)
}

.changeTechProc {
  --base-reason-bg-color: var(--card-change-tech-proc-reason)
}

.unknown {
  --base-reason-bg-color: var(--card-unknown-reason);
}

.unknownBug {
  --base-reason-bg-color: var(--card-unknown-bug-reason)
}