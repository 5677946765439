.errorPage{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-bg-default);
    color:var(--color-typo-primary);
    overflow: hidden;
}
.actions{
    display: flex;
    flex-direction: row;
    gap: var(--space-2xs);
}