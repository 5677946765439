.body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.header {
    width: 100%;
    flex: 0 0 var(--space-3xl);
    background: var(--color-bg-system);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-xs);
    background: var(--color-bg-system);
    overflow: hidden;
}

.mainBlock {
    width: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--space-xs);
    gap: var(--space-xs)
}
.actionsBlock{
    width: 100%;
    flex: 0 0 fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: var(--space-xs);


}
.field{
    width: 100%;
    flex: 0 0 fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-2xs);
    .form{
        width: 100%;
        overflow: hidden;
    }
}
.filesContainer{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-2xs);
    flex-wrap: wrap;
}